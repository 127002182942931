<template>
<!-- Общий контейнер шаблона компонента -->
<div class="container center-content d-flex justify-content-center flex-wrap">
	<h1>Бинарный поиск</h1>

	<div>
		<!-- Визуализация списка элементов списка и привязка значений -->
		<!-- TransitionGroup необходим для работы анимации элементов при изменении списка -->
		<TransitionGroup name="numbers-list" tag="div" class="row animate__animated animate__zoomIn">
			<input v-for="(item, index) in numbersList" :key="item.key"
				v-model="item.value"
				readonly
				class="col-sm binary-search-item"
				:class="[item.selectedClass, item.targetClass]"
				@click="SearchNumber(item)"
				type="number" />
		</TransitionGroup>
	</div>

	<div class="d-flex flex-row">
		<!--Рандомизация значений списка -->
		<button
			class="btn btn-dark btn-randomize"
			@click="RandomizeNumbers">Рандомизировать</button>
	</div>
</div>
</template>

<script>
export default {
	data() {
		return {
			// Список элементов (key: int, value: int, selectedClass: string, targetClass: string)
			numbersList: [],
			// Найденый элемент в момент бинарного поиска
			searchTargetResult: undefined,
			// Целевой выбранный элемент для бинарного поиска
			searchTargetSelected: undefined,
			// Заблокировать функцию поиск, пока идёт сортировка
			lockSearchFunction: false,
			// Проверка первого запуска для проигрывания анимации сортировки
			isFirstStart: true,
		}
	},
	created() {
		this.RandomizeNumbers();
	},
	methods: {
		/**
		 * Устанавливает задержку для асинхронной операции в секундах.
		 * @param {float} delayTimeInSeconds задержка в секундах
		 * @return {Promise} объект состояния для выполнения асинхронной операции.
		 * @public
		 */
		AsyncWait(delayTimeInSeconds) {
			return new Promise(r => setTimeout(r, delayTimeInSeconds));
		},
		/**
		 * Сортирует список элементов по возрастанию параметра ".value".
		 * @public
		 */
		SortNumbersList() {
			this.numbersList.sort((a, b) => {
				return a.value - b.value;
			});
		},
		/**
		 * Устанавливает случайные значения в список из 10 элементов.
		 * @public
		 */
		RandomizeNumbers: async function() {
			const rangeValues = 500;

			this.numbersList = [];
			this.lockSearchFunction = true;
			this.searchTargetResult = undefined;

			for (let index = 0; index < 10; index++) {	
				const randomNumber = Math.random() * rangeValues;
				const newValue = Math.floor(randomNumber);

				this.numbersList.push({
					key: index,
					value: newValue,
					selectedClass: 'binary-search-default',
					targetClass: ''
				});
			}

			if (this.isFirstStart) {
				await this.AsyncWait(1000);
				this.isFirstStart = false;
			}

			this.SortNumbersList();
			this.lockSearchFunction = false;
		},
		/**
		 * Устанавливает элемент списка в качестве цели и запускает визуализацию бинарного поиска по значению.
		 * @param {object} item элемент списка
		 * @public
		 */
		SearchNumber(item) {
			if (this.lockSearchFunction) return;

			if (this.searchTargetSelected != undefined)
				this.searchTargetSelected.targetClass = '';

			this.searchTargetSelected = item;
			this.searchTargetSelected.targetClass = 'binary-search-target';

			this.BinarySearch(item.value);
		},
		/**
		 * Воспроизводит бинарный поиск по списку элементов и возвращает результат
		 * в виде числового индекса позиции искомого элемента в списке.
		 *
		 * @param {int} targetNumber искомое число в списке
		 * @return {int} индекс искомого элемента в списке, или -1 в случае если элемент найти не удалось.
		 * @public
		 */
		BinarySearch: async function(targetNumber) {
			const numbersList = this.numbersList;
			let min = 0;
			let max = numbersList.length - 1;
			let middle = (max + 1) / 2;
			let resultIndex = -1;

			do
			{
				middle = parseInt(middle);

				const item = numbersList[middle];
				let value = item.value;

				if (targetNumber == value)
					resultIndex = middle;
				else if (targetNumber < value)
					max = middle - 1;
				else
					min = middle + 1;

				if (this.searchTargetResult != undefined)
					this.searchTargetResult.selectedClass = 'binary-search-default';

				this.searchTargetResult = item;
				this.searchTargetResult.selectedClass = 'binary-search-result';

				await this.AsyncWait(500);

				middle = (min + max + 1) / 2;
			} while (min <= max && resultIndex == -1);

			return resultIndex;
		}
	}
}
</script>

<style scoped>
	/* Анимация списка при сортировке */
	.numbers-list-move,
	.numbers-list-enter-active,
	.numbers-list-leave-active {
		-webkit-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		transition: all .5s ease-in-out;
	}

	/* Стиль кнопки рандомизации */
	.btn-randomize {
		height: 35px;
		border-radius: 8px;
		margin: 5px;
		background-color: rgb(65, 65, 65);
		color: white;
	}

	/* Стиль поля поиска */
	.binary-search-input {
		width: 250px;
		height: 35px;
		font-size: 12pt;
		text-align: center;
		border-radius: 8px;
		margin: 5px;
		background-color: rgb(65, 65, 65);
		color: white;
		box-shadow: none !important;
		border: 1px solid rgb(32, 32, 32) !important;
	}

	/* Стиль поля поиска: цвет "placeholder" */
	.binary-search-input::placeholder {
		color: rgb(177, 177, 177);
	}
	
	/* Централизация контента на экране */
	.center-content {
		position: relative;
		top: 50px;
	}
	
	/* Стиль элементов списка */
	.binary-search-item {
		height: 40px;
		text-align: center;
		background-color: rgb(233, 233, 233);
		font-size: 12pt;
		border-radius: 12px;
		margin: 5px;
		background-color: rgb(37, 37, 37);
		color: white;
		box-shadow: none !important;
		border: 1px solid rgb(32, 32, 32) !important;
		cursor: pointer;
	}

	/* Стиль элементов списка: если является целью поиска */
	.binary-search-result {
		outline: 2px solid rgb(230, 35, 35);

		-webkit-transition: height .2s ease-in-out;
		-moz-transition: height .2s ease-in-out;
		-o-transition: height .2s ease-in-out;
		transition: height .2s ease-in-out;

		-webkit-transition: outline .2s ease-in-out;
		-moz-transition: outline .2s ease-in-out;
		-o-transition: outline .2s ease-in-out;
		transition: outline .2s ease-in-out;
	}

	/* Стиль элементов списка: если не является целью поиска */
	.binary-search-default {
		outline: 2px solid rgb(168, 168, 168);
	}

	/* Стиль элементов списка: если выбран в качестве искомой цели */
	.binary-search-target {
		background-color: rgb(85, 85, 85);
	}

	/* Скрытие стрелок у элементов типа "input" (FireFox) */
	input[type="number"] {
		-moz-appearance: textfield;
		-webkit-appearance: textfield;
		appearance: textfield;
	}
	
	/* Скрытие стрелок у элементов типа "input" (Chrome и прочее) */
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		display: none;
	}
</style>