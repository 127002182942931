<script>
import { isMobile } from 'mobile-device-detect';

export default {
	mounted() {
		$(document).ready(() => setTimeout(() => {
			this.isLoadedContent = true;

			let pageNameId = location.hash;
			pageNameId = pageNameId.replace('#', '');
			pageNameId = pageNameId.split('-')[0];

			for (let index = 0; index < this.containerNames.length; index++) {
				let element = this.containerNames[index];
				if (element == pageNameId) {
					this.setContainerId(index);
					break;
				}
			}

			this.cookiesEnabled = true;

			setTimeout(() => {
				if (this.promptDisable || $cookies.get('prompt-lock')) return;
				this.promptOpen = true;
				this.promptVisible = true;
			}, 3500);
		}, 500));
	},
	methods: {
		swipe: function(direction) {
			if (this.waitScroll) return;

			if (direction == 'up' || direction == 'left') {
				this.changeContainer('back');
      } else if (direction == 'down' || direction == 'right') {
				this.changeContainer('next');
      }

			if (!this.promptVisible && !this.promptDisable) {
				this.promptDisable = true;
			}

			if (this.promptOpen) {
				this.promptOpen = false;
			}

			if (this.cookiesEnabled && !$cookies.get('prompt-lock')) {
				$cookies.set('prompt-lock', true, this.promptCookiesTime);
			}
		},
		handleSwipe: function(direction) {
			if (!isMobile) return;
			this.swipe(direction);
		},
		handleWheelScroll: function (event) {
			this.swipe(event.deltaY < 0 ? 'up' : event.deltaY > 0 ? 'down' : undefined);
		},
		setContainerId: function(containerId) {
			if (containerId > this.maxContainers) {
				containerId = this.minContainers;
			} else if (containerId < this.minContainers) {
				containerId = this.maxContainers;
			}

			this.containerId = containerId;
			location.hash = this.containerNames[this.containerId];

			if (this.promptOpen) {
				this.setWaitScroll(700);
			} else if (!isMobile) {
				this.setWaitScroll(100);
			}
		},
		setWaitScroll: function(delay) {
			this.waitScroll = true;
			setTimeout(() => this.waitScroll = false, delay);
		},
		changeContainer: function(direction) {
			let containerId = this.containerId + (direction == 'next' ? 1 : -1);

			if (this.promptOpen) {
				setTimeout(() => this.setContainerId(containerId), 700);
			} else {
				this.setContainerId(containerId);
			}
		},
	},
	created: function () {
		window.addEventListener('wheel', this.handleWheelScroll);
	},
	destroyed: function () {
		window.removeEventListener('wheel', this.handleWheelScroll);
	},
  data() {
    return {
      containerId: 0,
			containerNames: [
				'profile',
				'direction',
				'languages',
			],
			minContainers: 0,
			maxContainers: 2,
			waitScroll: false,
			promptDisable: false,
			promptVisible: false,
			promptOpen: false,
			promptCookiesTime: '10MIN',
			isLoadedContent: false,
			cookiesEnabled: false,
    }
  },
}
</script>

<template>
	<div v-show="isLoadedContent" class="mobile-swipe" v-touch:swipe="handleSwipe">
		<div class="container pt-3 d-flex justify-content-center animate__animated animate__bounceIn">
			<h1>Портфолио</h1>
		</div>

		<div 
			v-show="containerId == 0"
			class="container-fluid pt-3 pb-3 bg-gray-1">
				<profile-component/>
		</div>

		<div
			v-show="containerId == 1"
			class="container-fluid pt-3 pb-3 bg-gray-1">
				<direction-component/>
		</div>

		<div
			v-show="containerId == 2"
			class="container-fluid pt-3 pb-3 bg-gray-1">
				<basic-languages-component/>
		</div>

		<prompt-component
			v-bind:prompt-open="promptOpen"
			v-bind:prompt-visible="promptVisible"/>
	</div>
</template>

<style scoped>
	.bg-gray-1 {
		/* background-color: rgb(59, 59, 59); */
		/* background-color: rgba(59, 59, 59, 0.918); */
	}

	.mobile-swipe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
</style>