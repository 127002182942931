<script>
import { isMobile } from 'mobile-device-detect';

export default {
	methods: {
		promptGetTextMessage: function() {
			return isMobile ? this.mobileMessage : this.desktopMessage;
		}
	},
  data() {
    return {
			desktopMessage: 'Используйте колёсико мыши для путешествия по сайту',
			mobileMessage: 'Свайпните экран в сторону для путешествия по сайту',
    }
  },
	props: {
		promptVisible: false,
		promptOpen: false
	},
	computed: {
		promptAnimate: function() {
			return this.promptOpen ? 'animate__fadeInDown' : 'animate__fadeOutUp'
		},
		promptDevice: function() {
			return isMobile ? 'prompt-mobile' : 'prompt-desktop'
		}
	}
}
</script>

<template>
	<div v-if="promptVisible" v-bind:class="[promptAnimate, promptDevice]"
		class="prompt pt-3 d-flex justify-content-center animate__animated">
		<p>
			{{ this.promptGetTextMessage() }}
		</p>
	</div>
</template>

<style scoped>
	.prompt {
		position: absolute !important;
		height: auto;
		width: auto;
		left: 25px;
		right: 25px;
		bottom: 25px;
		margin-left: auto;
		margin-right: auto;
		background-color: rgba(0, 0, 0, 0.7);
		border: 4px solid #4d1212;
  	border-radius: 5px;
		backdrop-filter: blur(10px);
		z-index: 1;
	}

	.prompt-desktop {
		/* width: 50%; */
	}

	.prompt-mobile {
		/* width: auto; */
	}

	.prompt > p {
		margin-left: 5px;
		margin-right: 5pt;
		font-size: 14pt;
		text-align: center;
	}

	@media (min-width: 1400px) {
		.prompt > p {
			margin-left: 5px;
			margin-right: 5pt;
			font-size: 20pt;
			text-align: center;
		}
	}

	@media (min-width: 1700px) {
		.prompt > p {
			margin-left: 5px;
			margin-right: 5pt;
			font-size: 24pt;
			text-align: center;
		}
	}

	@media (min-width: 2400px) {
		.prompt > p {
			margin-left: 5px;
			margin-right: 5pt;
			font-size: 30pt;
			text-align: center;
		}
	}

	@media (min-width: 3000px) {
		.prompt > p {
			margin-left: 5px;
			margin-right: 5pt;
			font-size: 35pt;
			text-align: center;
		}
	}
</style>