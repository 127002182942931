<script>
	import hljs from 'highlight.js';

	export default {
		mounted() {
			$(document).ready(() => {
				hljs.initHighlighting();
			});
		},
		methods: {
			setLanguage: function(languageType) {
				this.codeType = languageType;
				this.languageClick = true;

				setTimeout(() => {
					if (languageType != this.languageHover) return;
					this.languageClick = false;
				}, 1000);
			},
			mouseLanguageHover: function(languageType) {
				this.languageHover = languageType;
				this.languageClick = false;
			},
			languageClassSelector: function(languageType) {
				if (this.languageHover != languageType) return '';
				return this.languageClick ? 'animate__jello' : 'animate__pulse'
			},
		},
		data() {
			return {
				languageHover: '',
				languageClick: false,
				codeType: '',
				codes: {
					java: `class Application {
	public static void main(String[] args) {
		System.out.println("Hello World!");
	}
}`,
					python: `#! /usr/bin/env python
# -*- coding: utf-8 -*-
print("Hello World!")`,
					php: `<?php
namespace App\Http\Controllers;

class Application extends Controller
{
	public function __construct()
	{
		echo "Hello World!";
	}
}`,
					lua: `local function init()
	print('Hello World!')
end
hook.Add('Initializate', 'Welcome', init)`,
					javascript: `$(document).ready(function() {
	console.log('Hello World!');
});`,
					csharp: `class Application
{
	static void Main(string[] args)
	{
		Console.WriteLine("Hello World!");
	}
}`,
				}
			}
		}
	}
</script>

<template>
	<div>
		<div class="d-flex justify-content-center animate__animated animate__zoomIn">
			<h2>Основные языки</h2>
		</div>

		<div class="d-flex justify-content-center flex-wrap">
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('csharp')"
						@mouseover="mouseLanguageHover('csharp')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('csharp')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/csharp/csharp_128x128.png"
						alt="C#">
				</p>
				<h3 class="text-center">C#</h3>
			</div>
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('python')"
						@mouseover="mouseLanguageHover('python')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('python')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/python/python_128x128.png"
						alt="Python">
				</p>
				<h3 class="text-center">Python</h3>
			</div>
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('lua')"
						@mouseover="mouseLanguageHover('lua')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('lua')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/lua/lua_128x128.png"
						alt="Lua">
				</p>
				<h3 class="text-center">Lua</h3>
			</div>
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('java')"
						@mouseover="mouseLanguageHover('java')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('java')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java_128x128.png"
						alt="Java">
				</p>
				<h3 class="text-center">Java</h3>
			</div>
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('javascript')"
						@mouseover="mouseLanguageHover('javascript')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('javascript')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/javascript/javascript_128x128.png"
						alt="JavaScript">
				</p>
				<h3 class="text-center">JavaScript</h3>
			</div>
			<div class="animate__animated animate__flipInX">
				<p>
					<img v-on:click="setLanguage('php')"
						@mouseover="mouseLanguageHover('php')" @mouseleave="mouseLanguageHover('')"
						v-bind:class="languageClassSelector('php')"
						class="programming-language-icon animate__animated"
						src="https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/php/php_128x128.png"
						alt="PHP">
				</p>
				<h3 class="text-center">PHP</h3>
			</div>
		</div>

		<pre class="code-block">
			<code
				v-show="codeType=='csharp'"
				language="csharp"
				class="code animate__animated animate__flipInX">{{ codes.csharp }}</code>

			<code
				v-show="codeType=='javascript'"
				language="javascript"
				class="code animate__animated animate__flipInX">{{ codes.javascript }}</code>

			<code
				v-show="codeType=='lua'"
				language="lua"
				class="code animate__animated animate__flipInX">{{ codes.lua }}</code>

			<code
				v-show="codeType=='php'"
				language="php"
				class="code animate__animated animate__flipInX">{{ codes.php }}</code>

			<code
				v-show="codeType=='python'"
				language="python"
				class="code animate__animated animate__flipInX">{{ codes.python }}</code>

			<code
				v-show="codeType=='java'"
				language="java"
				class="code animate__animated animate__flipInX">{{ codes.java }}</code>
		</pre>
	</div>
</template>

<style scoped>
	.programming-language-icon {
		width: 100px;
		height: 100px;
	}

	.code-block {
		margin-top: 25px;
		display: inline-block;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.code {
		border-radius: 25px;
		margin-bottom: 25px;
		border: 2px solid #42b9dd;
		font-size: 8pt;
	}

	@media (min-width: 1400px) {
		.code { font-size: 12pt; }
	}

	@media (min-width: 1700px) {
		.code { font-size: 14pt; }
	}

	@media (min-width: 2400px) {
		.code { font-size: 16pt; }
	}

	@media (min-width: 3000px) {
		.code { font-size: 18pt; }
	}

	@media (min-width: 1400px) {
		.programming-language-icon {
			margin-top: 10px;
			width: 150px;
			height: 150px;
		}
	}

	@media (min-width: 1700px) {
		.programming-language-icon {
			margin-top: 20px;
			width: 200px;
			height: 200px;
		}
	}

	@media (min-width: 2400px) {
		.programming-language-icon {
			margin-top: 40px;
			width: 250px;
			height: 250px;
		}
	}

	@media (min-width: 3000px) {
		.programming-language-icon {
			margin-top: 50px;
			width: 300px;
			height: 300px;
		}
	}
</style>