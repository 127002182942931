/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// let ExampleComponent = require('./components/ExampleComponent.vue').default;
let BlankHref = require('./components/BlankHref.vue').default;
let PromptComponent = require('./components/PromptComponent.vue').default;
let ProfileComponent = require('./components/ProfileComponent.vue').default;
let DirectionComponent = require('./components/DirectionComponent.vue').default;
let BasicLanguagesComponent = require('./components/BasicLanguagesComponent.vue').default;
let Portfolio = require('./components/Portfolio.vue').default;
let BinarySearchComponent = require('./components/TestWorks/BinarySearchComponent.vue').default;

// Vue.component('example-component', ExampleComponent);
Vue.component('blank', BlankHref);
Vue.component('prompt-component', PromptComponent);
Vue.component('profile-component', ProfileComponent);
Vue.component('direction-component', DirectionComponent);
Vue.component('basic-languages-component', BasicLanguagesComponent);
Vue.component('portfolio-component', Portfolio);
Vue.component('binary-search-component', BinarySearchComponent);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

/**
 * JS Particles
 */

 $(function() {
	particlesJS.load('particles', this.location.origin + '/data/particles.json');
});