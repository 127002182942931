<script>
export default {
	props: {
		url: ''
	}
}
</script>

<template>
	<a v-bind:href="url" target="_blank">
		<slot></slot>
	</a>
</template>